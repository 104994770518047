import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
  `,
  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Bismillahirrahmanirrahim 
Assalamualaikum warahmatullahi wabarakatuh 

Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}* 

Dengan memohon rahmat dan ridho Allah Subhanahu wa Ta’ala, kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa restu secara langsung dalam syukuran pernikahan anak kami: 

*Atina Darajat An Nizhamiya*
_Putri dari Bapak Nidlomun Niam dan Ibu Sri Darmawati_
& 
*Dimas Syahputra* 
_Putra dari Bapak Yulianto dan Ibu Nanik Sulistyo Tri Subekti_

Insha Allah acara pernikahan kami akan dilaksanakan pada:
Tanggal: *Minggu, 18 Februari 2024*
Waktu: 11.00 - 13.00 WIB 
Lokasi: *Convention Hall Masjid Agung Jawa Tengah. Jalan Gajah Raya, Sambirejo, Kec. Gayamsari, Kota Semarang, Jawa Tengah.*

Tanpa mengurangi rasa hormat, berikut link undangan website anak kami:
{{LINK}}

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam syukuran pernikahan anak kami. 

Semoga kita semua bisa bertemu dalam keadaan sehat wal afiat dan selalu dalam lindungan Allah Subhanahu wa Ta’ala.

Wassalamualaikum Warahmatullahi Wabarakatuh 

_Kami yang berbahagia,_
*Nidlomun Ni'am, Sri Darmawati, dan keluarga*`,
[TYPE_BROADCAST.CUSTOM_INVITATION_2]: `Dear *{{GUEST_NAME}}*,

Tanpa mengurangi rasa hormat, perkenankan kami mengundang untuk menghadiri acara pernikahan kami:

*Atina Darajat An Nizhamiya & Dimas Syahputra*

Insha Allah acara pernikahan kami akan dilaksanakan pada:
Tanggal: *Minggu, 18 Februari 2024*
Lokasi: *Convention Hall Masjid Agung Jawa Tengah. Jalan Gajah Raya, Sambirejo, Kec. Gayamsari, Kota Semarang, Jawa Tengah.*

Berikut detail acara kami:

*{{LINK}}*

Merupakan suatu kebahagiaan bagi kami apabila berkenan untuk hadir dan memberikan doa restu. 

Atas perhatiannya kami ucapkan terima kasih.

With love,
*Atina&Dimas*
  `
}